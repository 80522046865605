<template>
 
  <div>
    <div style="position:fixed;z-index:999;width:100%;left:0px; background-color:white" class="nav hidden visible-xs">
      <div class="" style="padding-top:1vh; padding-bottom:1vh">
          <div class="container-fluid">
          <div class="row">
              <div class="col-xs-3" style="top:7px">
                <p style="  display:flex;
                  align-items:center;">
                  <img
                      src="/img/bcart.png"
                      alt="aljani"
                      align="middle"
                      class="img-responsive"
                      style="height:25px;width:auto;float:left;vertical-align:top;"
                  />
            
                </p>
              </div>
              <div class="col-xs-7">
            
              </div>
              <!-- <div class="col-xs-2" @click="openNav()" style="top:7px">
                  <i
                  style="font-size: 18px; color:black"
                  class="fa fa-bars"
                  ></i>
              </div> -->
          </div>
          </div>
      </div>
    </div>
   
    <div id="mySidenav" style="z-index:1000" class="sidenav hidden visible-xs">
      <a href="javascript:void(0)" class="closebtn" @click="closeNav()">&times;</a>
      <a @click.prevent="route('/')" >Home</a>
      <a @click.prevent="route('/contact_us')">Contact Us</a>
      <a @click.prevent="route('/about')">About Us</a>
      <a @click.prevent="route('/faqs')">FAQs</a>
    </div>
    <nav class="navbar navbar-fixed-top hidden-xs" style="background:white">
      <div class="container" style="padding:10px">
        <div class="navbar-header" style="display:inline-block;">
          <a class="navbar-brand" href="/" style="padding-top:5px;">
            <p style="  display:flex;
              align-items:center;">
              <img
                  src="images/h color logo @3x.png"
                  alt="aljani"
                  align="middle"
                  class="img-responsive"
                  style="height:45px;width:auto;float:left;vertical-align:top;"
              />
            
            </p>
          </a>
        </div>
        <ul
          class="nav navbar-nav navbar-right"
          style="display:inline-block;font-size:10px;"
        >
          <li class="nav-item "><router-link  style="color: black;" to="/">Home</router-link></li>
          <li class="nav-item"><router-link style="color: black;" to="/contact_us">Contact Us</router-link></li>
          <li class="nav-item">
            <router-link class="web-active" style="color: black;" to="/about">About Us</router-link>
          </li>
          <li class="nav-item"><router-link style="color: black;" to="/faqs">FAQs</router-link></li>
        </ul>
        <!--<ul class="nav navbar-nav navbar-right">
          <li>
              <a href="#"><i class="fa fa-search"></i></a>
          </li>
          <li>
              <a href="#"><i class="fa fa-shopping-cart"></i></a>
          </li>
          </ul>-->
      </div>
    </nav>
  </div>
 
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      apiDomain: process.env.VUE_APP_API_DOMAIN
    };
  },
  computed: {
    ...mapState({
      cartStore: state => state.cartStore
    }),

    cartProduct() {
      return (this.cartProducts = this.cartStore.cartItems);
    },
    main() {
      return this.$route.name !== "home" ? true : false;
    },

    total() {
      return _.sumBy(this.cartStore.cartItems, function(o) {
        return o.price * o.qty;
      });
    },

    header() {
      return this.$route.meta.header
        ? this.$route.meta.header
        : this.$store.getters.getRouteHeader;
    }
  },

  methods: {
    delFromCart(index) {
      this.$store.dispatch("deleteCartItem", index);
    },

    test() {
      alert(45);
    },

    route(path) {
      this.$router.push({ path });
      this.closeNav()
    },
    checkNav() {
      if (this.$route.name == "suppliers") {
        return false;
      }
      return true;
    },
    goBack() {
      this.$router.go(-1);
    },

    

    openNav() {
      document.getElementById("mySidenav").style.width = "250px";
      //document.getElementById("main").style.marginLeft = "250px";
      //document.body.style.zIndex = -1;
      //document.body.style.backgroundColor = "rgba(0,0,0,0.9)";
    },

    /* Set the width of the side navigation to 0 and the left margin of the page content to 0, and the background color of body to white */
    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
      //document.getElementById("main").style.marginLeft = "0";
      //document.body.style.backgroundColor = "white";
    }
  },

  created() {
   
  }
};
</script>

<style lang="scss" scoped>
.fixed-navbar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
.back {
  display: inline;
  text-align: center;
}
.badge-notify {
  background: red;
  position: relative;
  top: -10px;
  left: -5px;
}
.quick-btn .label {
  position: absolute;
  top: -5px;
  right: -5px;
}

.logo-text {
    color: black;
    font-size: 2.052em;
    padding-left: 10px

}

 
</style>
