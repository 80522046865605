<template>
<div>
        <base-nav-bar/>
        <transition appear name="custom-classes-transition" enter-active-class="animated fadeIn">
           <router-link to="/">Home of mine</router-link>
        </transition>
</div>
</template>
 
<script>
import BaseNavBar from '../../components/BaseNavBar'
    export default {
        components: {
            BaseNavBar
        }
    }
</script>

